import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  color: ", ";\n  padding-right: 0.6rem;\n  margin: 0;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n  font-size: 20px;\n  padding-right: 0.6rem;\n  margin: 0;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return "".concat(props.theme.spacing.gutter);
});
export var Header = styled.div(_templateObject2(), function (props) {
  return props.theme.spacing.gutter;
});
export var Title = styled.h4(_templateObject3(), function (_ref) {
  var size = _ref.size,
      typography = _ref.theme.typography;
  return size === "lg" ? typography.h4FontSize : typography.baseFontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.colors.baseFont;
});
export var Paragraph = styled.p(_templateObject4(), function (props) {
  return props.theme.typography.smallFontSize;
}, function (props) {
  return props.theme.colors.lightFont;
});