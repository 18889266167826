import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\ncolor: #262c3e;\nfont-family: \"Noto Sans\";\nfont-size: 22px;\nfont-weight: 700;\nmargin-bottom: 25px;\nmargin-left: 56px;\nposition: relative;\n\n&:before {\n  content: '';\n  position: absolute;\n  left: -56px;\n  top: 8px;\n  width: 36px;\n  height: 9px;\n  background-color: #000000;\n  background-image: linear-gradient(to right, #30c4ea 0%, #34fdde 100%);\n}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Title = styled.div(_templateObject());