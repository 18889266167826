import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject41() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 100%;\n"]);

  _templateObject41 = function _templateObject41() {
    return data;
  };

  return data;
}

function _templateObject40() {
  var data = _taggedTemplateLiteral(["\n  grid-area: quantity;\n  min-width: 120px;\n  ", "\n"]);

  _templateObject40 = function _templateObject40() {
    return data;
  };

  return data;
}

function _templateObject39() {
  var data = _taggedTemplateLiteral(["\n    ", "\n  "]);

  _templateObject39 = function _templateObject39() {
    return data;
  };

  return data;
}

function _templateObject38() {
  var data = _taggedTemplateLiteral(["\n  margin: 0 15px;\n  ", ";\n"]);

  _templateObject38 = function _templateObject38() {
    return data;
  };

  return data;
}

function _templateObject37() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n"]);

  _templateObject37 = function _templateObject37() {
    return data;
  };

  return data;
}

function _templateObject36() {
  var data = _taggedTemplateLiteral(["\n  grid-area: unitPrice;\n"]);

  _templateObject36 = function _templateObject36() {
    return data;
  };

  return data;
}

function _templateObject35() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  grid-area: trash;\n"]);

  _templateObject35 = function _templateObject35() {
    return data;
  };

  return data;
}

function _templateObject34() {
  var data = _taggedTemplateLiteral(["\n  grid-area: totalPrice;\n  ", "\n"]);

  _templateObject34 = function _templateObject34() {
    return data;
  };

  return data;
}

function _templateObject33() {
  var data = _taggedTemplateLiteral(["\n    ", "\n  "]);

  _templateObject33 = function _templateObject33() {
    return data;
  };

  return data;
}

function _templateObject32() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n"]);

  _templateObject32 = function _templateObject32() {
    return data;
  };

  return data;
}

function _templateObject31() {
  var data = _taggedTemplateLiteral(["\n  p {\n    text-align: right;\n  }\n"]);

  _templateObject31 = function _templateObject31() {
    return data;
  };

  return data;
}

function _templateObject30() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject30 = function _templateObject30() {
    return data;
  };

  return data;
}

function _templateObject29() {
  var data = _taggedTemplateLiteral(["\n    ", "\n  "]);

  _templateObject29 = function _templateObject29() {
    return data;
  };

  return data;
}

function _templateObject28() {
  var data = _taggedTemplateLiteral(["\n  display: none;\n  ", ";\n"]);

  _templateObject28 = function _templateObject28() {
    return data;
  };

  return data;
}

function _templateObject27() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n"]);

  _templateObject27 = function _templateObject27() {
    return data;
  };

  return data;
}

function _templateObject26() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  display: flex;\n  justify-content: center;\n  ", "\n\n  p {\n    margin: 0;\n  }\n"]);

  _templateObject26 = function _templateObject26() {
    return data;
  };

  return data;
}

function _templateObject25() {
  var data = _taggedTemplateLiteral(["\n    ", "\n  "]);

  _templateObject25 = function _templateObject25() {
    return data;
  };

  return data;
}

function _templateObject24() {
  var data = _taggedTemplateLiteral(["\n  font-weight: bold;\n  ", ";\n"]);

  _templateObject24 = function _templateObject24() {
    return data;
  };

  return data;
}

function _templateObject23() {
  var data = _taggedTemplateLiteral(["\n  font-weight: normal;\n  flex-direction: column;\n"]);

  _templateObject23 = function _templateObject23() {
    return data;
  };

  return data;
}

function _templateObject22() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  color: rgba(125, 125, 125, 0.6);\n"]);

  _templateObject22 = function _templateObject22() {
    return data;
  };

  return data;
}

function _templateObject21() {
  var data = _taggedTemplateLiteral(["\n  color: #111627;\n  font-weight: 700;\n  letter-spacing: 0.35px;\n"]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n  flex-grow: 1;\n  display: flex;\n  justify-content: flex-start;\n  font-size: 14px;\n  white-space: nowrap;\n  background-color: white;\n  padding: 0px 15px;\n"]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-auto-columns: max-content;\n  grid-template-columns: repeat(auto-fit, minmax(166px, 500px));\n  margin-left: -15px;\n  ", "\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n    ", "\n  "]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  flex-flow: column;\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  margin: 6px 0 22px 0;\n  text-align: left;\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  font-size: 14px;\n  font-weight: 400;\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  grid-area: description;\n  height: 100%;\n  ", "\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n    ", "\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  margin: 0 0 0 20px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  ", "\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  grid-area: photo;\n  display: flex;\n  align-items: center;\n  align-self: top;\n  width: 55px;\n  height: 55px;\n\n  background-color: #f0f0f0;\n  border: 1px solid #d1d1d1;\n  border-radius: 55px;\n\n  ", "\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  margin: 0 auto;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  padding: 0;\n  margin: 0 15px 0 0;\n  width: 50px;\n\n  > div {\n    display: flex;\n  }\n\n  svg {\n    cursor: pointer;\n    justify-self: center;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  min-height: 100px;\n  max-height: min-content;\n  width: 100%;\n  ", "\n  align-items: center;\n  border-top: 1px solid rgba(50, 50, 50, 0.1);\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    ", "\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  grid-template-areas: \"photo description unitPrice quantity totalPrice trash\";\n  grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 0.5fr;\n  padding: 0.8rem 0.5rem;\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  grid-template-columns: 1fr 2fr 2fr;\n  grid-row-gap: 15px;\n  grid-column-gap: 20px;\n  grid-template-areas:\n    \"photo description description\"\n    \"unitPrice trash quantity\"\n    \". . totalPrice\";\n  padding: 1.6rem 0rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { css } from "styled-components";
import { media, styled } from "@styles";
var condenseWrapper = css(_templateObject());
var responsiveWrapper = css(_templateObject2(), media.mediumScreen(_templateObject3(), condenseWrapper));
export var Wrapper = styled.div(_templateObject4(), function (props) {
  return props.cartRowType === "condense" ? condenseWrapper : responsiveWrapper;
});
export var QuantityButtons = styled.div(_templateObject5());
var condensePhoto = css(_templateObject6());
var responsivePhoto = css(_templateObject7(), media.mediumScreen(_templateObject8(), condensePhoto));
export var Photo = styled.div(_templateObject9(), function (props) {
  return props.cartRowType === "condense" ? condensePhoto : responsivePhoto;
});
var condenseDescription = css(_templateObject10());
var responsiveDescription = css(_templateObject11(), media.mediumScreen(_templateObject12(), condenseDescription));
export var Description = styled.div(_templateObject13(), function (props) {
  return props.cartRowType === "condense" ? condenseDescription : responsiveDescription;
});
export var SinglePrice = styled.p(_templateObject14());
export var Sku = styled.p(_templateObject15());
var condenseAttributes = css(_templateObject16());
var responsiveAttributes = css(_templateObject17(), media.mediumScreen(_templateObject18(), condenseAttributes));
export var Attributes = styled.div(_templateObject19(), function (props) {
  return props.cartRowType === "condense" ? condenseAttributes : responsiveAttributes;
});
export var SingleAttribute = styled.p(_templateObject20());
export var Name = styled.p(_templateObject21());
export var LightFont = styled.span(_templateObject22(), function (props) {
  return props.theme.typography.smallFontSize;
});
var condensePrice = css(_templateObject23());
var responsivePrice = css(_templateObject24(), media.mediumScreen(_templateObject25(), condensePrice));
export var Price = styled.div(_templateObject26(), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.cartRowType === "condense" ? condensePrice : responsivePrice;
});
var condensePriceLabel = css(_templateObject27());
var responsivePriceLabel = css(_templateObject28(), media.mediumScreen(_templateObject29(), condensePriceLabel));
export var PriceLabel = styled.p(_templateObject30(), function (props) {
  return props.cartRowType === "condense" ? condensePriceLabel : responsivePriceLabel;
});
var condenseTotalPrice = css(_templateObject31());
var responsiveTotalPrice = css(_templateObject32(), media.mediumScreen(_templateObject33(), condenseTotalPrice));
export var TotalPrice = styled(Price)(_templateObject34(), function (props) {
  return props.cartRowType === "condense" ? condenseTotalPrice : responsiveTotalPrice;
});
export var Trash = styled.div(_templateObject35());
export var UnitPrice = styled(Price)(_templateObject36());
var condenseQuantity = css(_templateObject37());
var responsiveQuantity = css(_templateObject38(), media.mediumScreen(_templateObject39(), condenseQuantity));
export var Quantity = styled.div(_templateObject40(), function (props) {
  return props.cartRowType === "condense" ? condenseQuantity : responsiveQuantity;
});
export var ErrorMessages = styled.div(_templateObject41());