import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  query SimiliarTagsCategoryProductsList($categoryId: ID!, $tags: [ID!], $excludeIds: [ID!], $countryCode: CountryCode, $channel: String) {\n    products(filter: {categories:[$categoryId], tags: $tags, excludeIds: $excludeIds}, first: 20, channel: $channel) {\n      totalCount\n      edges{\n        node{\n          ...BasicProductItem\n        }\n      }\n    }   \n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  query CategoryProductList($categoryId: ID!, $excludeIds: [ID!], $countryCode: CountryCode, $channel: String) {\n    products(filter: {excludeIds: $excludeIds, categories:[$categoryId]},first:20, channel: $channel){\n        edges{\n          node{\n            ...BasicProductItem\n          }\n        }\n      }\n    }    \n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { TypedQuery } from "@temp/core/queries";
import gql from "graphql-tag";
import { basicProductItemFragment } from "src/views/Product/queries";
export var categoryProductList = gql(_templateObject(), basicProductItemFragment);
export var TypedCategoryProductsQuery = TypedQuery(categoryProductList);
export var similiarTagsCategoryProductList = gql(_templateObject2(), basicProductItemFragment);
export var TypedSimiliarTagsCategoryProductsQuery = TypedQuery(similiarTagsCategoryProductList);