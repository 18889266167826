import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-grow: 1;\n  overflow: hidden;\n\n  > img {\n    flex-grow: 1;\n    object-fit: contain;\n    max-width: 100%;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  color: #262c3e;\n  font-size: 16px;\n  font-weight: 700;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  color: #262c3e;\n  font-size: 16px;\n  font-weight: 400;\n  font-style: normal;\n  text-align: center;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    padding: 1.8rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background: ", ";\n  padding: 13px;\n  text-align: center;\n  height: 26rem;\n  display: flex;\n  flex-direction: column;\n  transition: 0.3s;\n\n  :hover {\n    background-color: ", ";\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.colors.hoverLightBackground;
}, media.largeScreen(_templateObject2()));
export var Title = styled.h4(_templateObject3());
export var Price = styled.p(_templateObject4());
export var Image = styled.div(_templateObject5());