import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  query Wishlist($countryCode:CountryCode) {\n    me {\n        wishlist {\n          items(first:50) {\n            totalCount\n            edges {\n              node {\n                product {\n                  ...BasicProductItem\n                }\n              }\n            }\n          }\n        }\n    } \n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { basicProductItemFragment } from "../Product/queries";
export var wishlist = gql(_templateObject(), basicProductItemFragment);
export var TypedWishlistQuery = TypedQuery(wishlist);