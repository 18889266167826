import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  margin-top: auto;\n  padding: 0 1rem;\n  margin-bottom: 1rem;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  padding: 1rem 1.25rem;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  border-bottom: 2px solid ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n        color: ", ";\n        align-items: center;\n        justify-content: center;\n        :hover {\n          cursor: pointer;\n          color: ", ";\n          background-color: ", ";\n          svg path {\n            fill: ", ";\n          }\n        }\n      "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n        :hover {\n          color: white;\n          cursor: pointer;\n          border-color: ", ";\n        }\n      "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: white;\n  border: 1px solid #cbced9;\n  overflow: auto;\n  height: 100%;\n  padding: 0;\n  transition: all 0.3s, color 0s, fill 0s;\n\n  display: flex;\n  flex-direction: column;\n  align-items: left;\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { css } from "styled-components";
export var Wrapper = styled.div(_templateObject(), function (props) {
  if (props.tileType === "hover") {
    return css(_templateObject2(), props.theme.tile.hoverBorder);
  }

  if (props.tileType === "addNew") {
    return css(_templateObject3(), props.theme.colors.secondary, props.theme.colors.white, props.theme.colors.secondary, props.theme.colors.white);
  }
});
Wrapper.displayName = "Tile";
export var Header = styled.div(_templateObject4(), function (props) {
  return props.theme.tile.divisionLine;
});
export var Content = styled.div(_templateObject5());
export var Footer = styled.div(_templateObject6());