var getVariantsStructuredData = function getVariantsStructuredData(variants) {
  var inStock = "https://schema.org/InStock";
  var outOfStock = "https://schema.org/OutOfStock";
  return variants.map(function (variant) {
    return {
      "@type": "Offer",
      availability: variant.quantityAvailable > 0 ? inStock : outOfStock,
      itemCondition: "https://schema.org/NewCondition",
      price: variant.pricing.price.gross.amount.toFixed(2),
      priceCurrency: variant.pricing.price.gross.currency,
      sku: variant.sku
    };
  });
};

export var structuredData = function structuredData(product) {
  var images = product.images.map(function (image) {
    return new URL(image.url).pathname;
  });
  var variants = product.variants;
  return JSON.stringify({
    "@context": "https://schema.org/",
    "@type": "Product",
    description: !product.seoDescription ? "".concat(product.description) : "".concat(product.seoDescription),
    image: images,
    name: !product.seoTitle ? "".concat(product.name) : "".concat(product.seoTitle),
    offers: getVariantsStructuredData(variants),
    url: location.href
  });
};