export function maybe(exp, d) {
  try {
    var result = exp();
    return result === undefined ? d : result;
  } catch (_unused) {
    return d;
  }
}
export function filterNotEmptyArrayItems(value) {
  return value !== null && value !== undefined;
}
export function distributeAcrossCubes(arr, cubesNo, sidesNo) {
  var cubes = [];
  var startAt = 0;

  for (var i = 0; i < cubesNo; i++) {
    var subList = [];

    for (var j = 0; j < sidesNo; j++) {
      subList.push(arr[startAt % arr.length]);
      startAt++;
    }

    cubes.push(subList);
  }

  return cubes;
}