import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  cursor: pointer;\n  color: ", ";\n  display: flex;\n  height: 60px;\n  font-size: 14px;\n  align-items: center;\n\n  svg {\n    margin-right: 15px;\n  }\n  \n  svg > path {\n    fill: ", ";\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  border-bottom: solid 1px #e1e1e9;\n\n  &:first-child {\n    border-top: solid 1px #e1e1e9;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: ", ";\n  text-transform: \"uppercase\";\n  padding-bottom: 2rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  \n  height: 100%;\n  /* height: auto; */\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var MenuHeader = styled.div(_templateObject2(), function (props) {
  return props.theme.typography.h3FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var MenuItemWrapper = styled.div(_templateObject3());
export var MenuItem = styled.div(_templateObject4(), function (props) {
  return props.active ? props.theme.colors.activeMenuOption : "#262c3e";
}, function (props) {
  return props.active ? props.theme.colors.activeMenuOption : "#8d8c92";
});