import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query ProductListingsList($channel: String, $categoriesLimit: Int) {\n    shop {\n      description\n      name\n    }\n    collections(\n      first:1,\n      filter: {promoted:true},\n      sortBy:{\n      direction:ASC,\n      field:PUBLICATION_DATE,\n      channel:$channel\n    }) {\n        edges{\n          node{\n            id\n            name\n            backgroundImage{\n              url\n            }\n            slug\n          }\n        }\n    }\n\n    categories(first:$categoriesLimit, level: 0) {\n      totalCount\n      edges {\n        node {\n             id\n            name\n            slug\n          }\n        }\n      }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
export var productListingsListQuery = gql(_templateObject());
export var TypedProductListingsListQuery = TypedQuery(productListingsListQuery);