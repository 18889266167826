import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", " \n  query SimiliarTagsCollectionProductsList($collectionId: ID!, $tags: [ID!], $excludeIds: [ID!], $countryCode: CountryCode, $channel: String) {\n    products(filter: {collections:[$collectionId], tags: $tags, excludeIds: $excludeIds}, first: 20, channel: $channel) {\n      totalCount\n      edges{\n        node{\n          ...BasicProductItem\n        }\n      }\n    }   \n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", " \n  query CollectionProductList($collectionId: ID!, $countryCode: CountryCode, $channel: String) {\n    collection(id: $collectionId, channel: $channel){\n      products(first:25){\n        edges{\n          node{\n            ...BasicProductItem\n          }\n        }\n      }\n    }    \n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { TypedQuery } from "@temp/core/queries";
import gql from "graphql-tag";
import { basicProductItemFragment } from "src/views/Product/queries";
export var collectionProductList = gql(_templateObject(), basicProductItemFragment);
export var TypedCollectionProductsQuery = TypedQuery(collectionProductList);
export var similiarTagsCollectionProductList = gql(_templateObject2(), basicProductItemFragment);
export var TypedSimiliarTagsCollectionProductsQuery = TypedQuery(similiarTagsCollectionProductList);