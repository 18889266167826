import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  align-items: center;\n  position: relative;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  position: relative;\n  &:not(:last-child) {\n    width: 100%;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  z-index: -1;\n  width: 100%;\n  height: 4px;\n  background-color: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  transform: none;\n  top: 40px;\n  right: 0;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  transform: none;\n  top: 40px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  white-space: pre;\n  display: block;\n  position: absolute;\n  top: 40px;\n  transform: translateX(-25%);\n  font-size: 14px;\n  letter-spacing: 2%;\n  color: #8a8f9e;\n  font-weight: 700;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 32px;\n  height: 32px;\n  border-radius: 50%;\n  background-color: transparent;\n  div {\n    background: linear-gradient(45deg, #30c4ea 0%, #34fdde 100%);\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  border-radius: 50%;\n  width: 32px;\n  height: 32px;\n  background: ", ";\n  border: solid 7px white;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Dot = styled.div(_templateObject(), function (props) {
  return props.done ? "#cbced9" : "#cbced9";
});
export var ActiveDot = styled.div(_templateObject2());
export var Label = styled.span(_templateObject3());
export var LeftLabel = styled(Label)(_templateObject4());
export var RightLabel = styled(Label)(_templateObject5());
export var ProgressBar = styled.div(_templateObject6(), function (props) {
  return props.done ? "#32e2e4" : "#cbced9";
});
export var Step = styled.div(_templateObject7());
export var Wrapper = styled.div(_templateObject8());