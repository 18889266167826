import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  border-top: 1px solid rgba(50, 50, 50, 0.1);\n  display: grid;\n  flex-flow: row wrap;\n  gap: 1rem;\n  margin: 0 1.875rem;\n  padding: 1.875rem 0;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  margin-bottom: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  margin: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  margin: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  overflow: auto;\n  height: 100%;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 26rem;\n  max-width: 100vw;\n  box-shadow: 6px 0px 30px rgba(0, 0, 0, 0.15);\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Content = styled.div(_templateObject2());
export var Cart = styled.div(_templateObject3(), function (props) {
  return "0 ".concat(props.theme.spacing.gutter);
});
export var EmptyCart = styled.div(_templateObject4(), function (props) {
  return "".concat(props.theme.spacing.gutter);
});
export var EmptyCartTitle = styled.h3(_templateObject5(), function (props) {
  return "".concat(props.theme.spacing.spacer);
});
export var EmptyCartDescription = styled.p(_templateObject6(), function (props) {
  return "".concat(props.theme.colors.lightFont);
}, function (props) {
  return "".concat(props.theme.spacing.spacer);
});
export var Footer = styled.div(_templateObject7());