import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  height: 40px;\n  border-radius: 19px;\n  padding: 0 24px;\n  display: flex;\n  align-items: center;\n  color: #3d3c43;\n  font-size: 14px;\n  font-weight: 400;\n  border: 1px solid #dcdce0;\n  cursor: pointer;\n  &:hover{\n    background-color: #dcdce0;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  gap: 17px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  font-size: 20px;\n  font-weight: 700;\n  text-align: center;\n  margin-bottom: 53px;\n  color: #262c3e;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 130px;\n  height: 130px;\n  border: 1px solid #dbdbe6;\n  background-color: #f3f3f8;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 44px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding: 0 20px 66px 20px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var ContentTags = styled.div(_templateObject());
export var SymbolTag = styled.div(_templateObject2());
export var ProductTitle = styled.h3(_templateObject3());
export var TagList = styled.div(_templateObject4());
export var Tag = styled.div(_templateObject5());