import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  mutation WishlistRemoveProduct($productId: ID!) {\n    wishlistRemoveProduct(productId: $productId) {\n        wishlistErrors{\n          code\n          field\n          message\n        }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation WishlistAddProduct($productId: ID!) {\n    wishlistAddProduct(productId: $productId) {\n        wishlistErrors{\n          code\n          field\n          message\n        }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { TypedMutation } from "@temp/core/mutations";
import gql from "graphql-tag";
export var wishlistAddProductMutation = gql(_templateObject());
export var TypedWishlistAddProductMutation = TypedMutation(wishlistAddProductMutation);
export var wishlistRemoveProductMutation = gql(_templateObject2());
export var TypedWishlistRemoveProductMutation = TypedMutation(wishlistRemoveProductMutation);