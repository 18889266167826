import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  query CategoryData($id: ID!, $countryCode: CountryCode) {\n    category(id: $id) {\n      id\n      name\n      seoDescription\n      seoTitle\n      ancestors (first: 10) {\n        totalCount\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n      backgroundImage{\n        url\n      }\n      products(first: 20) {\n        totalCount\n        edges {\n            node {\n              ...BasicProductItem\n            }\n        }\n      }   \n      children (first:10) {\n        edges {\n          node {\n            children {\n              totalCount\n            }\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { basicProductItemFragment } from "../Product/queries";
export var categoryDataQuery = gql(_templateObject(), basicProductItemFragment);
export var TypedCategoryDataQuery = TypedQuery(categoryDataQuery);