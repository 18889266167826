import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  height: 86px;\n  padding-top: 2rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  button {\n    margin-left: 2rem;\n  }\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  width: 60%;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n     flex-direction: column;\n  "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n      width: 100%;\n    "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n\n  > div {\n    width: 48%;\n    ", "\n  }\n\n  ", "\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n     width: 100%;\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  width: 50%;\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n    flex-direction: column;\n    width: 100%;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: 70%;\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  padding: 1rem 0 1.5rem;\n  width: 95%;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: none;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 95%;\n  padding-bottom: 1rem;\n  border-bottom: 1px solid ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 4rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  height: auto;\n  margin-bottom: 1.5rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n  justify-content: flex-start;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var TileWrapper = styled.div(_templateObject2());
export var Header = styled.div(_templateObject3(), function (props) {
  return props.theme.colors.dividerDark;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.h4FontSize;
});
export var HeaderSmall = styled(Header)(_templateObject4());
export var Content = styled.div(_templateObject5());
export var ContentOneLine = styled.div(_templateObject6(), media.smallScreen(_templateObject7()));
export var ContentEdit = styled.div(_templateObject8(), media.smallScreen(_templateObject9()));
export var ContentEditOneLine = styled.div(_templateObject10(), media.smallScreen(_templateObject11()), media.smallScreen(_templateObject12()));
export var ContentExtendInput = styled.div(_templateObject13());
export var Form = styled.form(_templateObject14(), function (props) {
  return props.theme.tile.backgroundColor;
});
export var FormButtons = styled.div(_templateObject15());