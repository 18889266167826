import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  query SimpleSearch(\n    $channel: String!\n    $query: String!\n  ){\n    products(\n      channel: $channel\n      filter: { search: $query }\n      first: 5\n      sortBy: { direction: DESC, field: NAME }\n    ) {\n      totalCount\n      edges {\n        node {\n          id\n          name\n          thumbnail: thumbnail(size:32) {\n            url\n            alt\n          }\n          category {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  fragment MainMenuSubItem on MenuItem {\n    id\n    name\n    category {\n      id\n      name\n    }\n    url\n    collection {\n      id\n      name\n    }\n    page {\n      slug\n    }\n    parent {\n      id\n    }\n  }\n\n  query MainMenu($channel: String!, $slug: String!) {\n    menu(channel: $channel, slug: $slug) {\n      id\n      items {\n        ...MainMenuSubItem\n        children {\n          ...MainMenuSubItem\n          children {\n            ...MainMenuSubItem\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
export var mainMenu = gql(_templateObject());
export var TypedMainMenuQuery = TypedQuery(mainMenu);
export var simpleSearch = gql(_templateObject2());
export var TypedSimpleSearchQuery = TypedQuery(simpleSearch);