import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  grid-area: totalPrice;\n  font-size: 14px;\n  font-weight: 700;\n  text-align: right;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  grid-area: totalText;\n  font-size: 16px;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  grid-area: discountPrice;\n  font-size: 14px;\n  font-weight: 700;\n  text-align: right;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  grid-area: discountText;\n  font-size: 16px;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  grid-area: shippingPrice;\n  font-size: 14px;\n  font-weight: 700;\n  text-align: right;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: shippingText;\n  font-size: 16px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  grid-area: subtotalPrice;\n  font-size: 14px;\n  font-weight: 700;\n  text-align: right;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  grid-area: subtotalText;\n  font-size: 16px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: grid;\n  font-size: ", ";\n  grid-template-areas:\n    \"subtotalText subtotalPrice\"\n    ", "\n    ", "\n    \"totalText totalPrice\";\n  grid-template-columns: 1.1fr 0.9fr;\n  grid-gap: 1.4rem;\n  padding: 0 0 1.4rem 0;\n  border-bottom: 1px solid rgba(50, 50, 50, 0.1);\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.showShipping && "\"shippingText shippingPrice\"";
}, function (props) {
  return props.showDiscount && "\"discountText discountPrice\"";
});
export var SubtotalText = styled.div(_templateObject2());
export var SubtotalPrice = styled.div(_templateObject3());
export var ShippingText = styled.div(_templateObject4());
export var ShippingPrice = styled.div(_templateObject5());
export var DiscountText = styled.div(_templateObject6());
export var DiscountPrice = styled.div(_templateObject7());
export var TotalText = styled.div(_templateObject8());
export var TotalPrice = styled.div(_templateObject9());