import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  margin: 0.4rem 0 0 0;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: auto;\n  min-width: 110px;\n  padding-left: 15px;\n\n  button {\n    color: #33f2e0;\n    background-color: #28304c;\n    background-image: none;\n    height: 40px;\n    margin-right: 10px;\n    padding: 5px 30px;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  flex: 1;\n  flex-grow: unset;\n  > div {\n    border: 0;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-size: 14px;\n  padding-top: 15px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  border: 0;\n  width: auto;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  input {\n    padding: 12px 20px;\n    color: #262c3e;\n    font-size: 14px;\n    background-color: #f6f6f6;\n    border: 1px solid #9ae2e5;\n    height: 40px;\n    min-width: 240px;\n    max-width: 300px;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var DiscountForm = styled.form(_templateObject());
export var Input = styled.div(_templateObject2());
export var InputWithButton = styled.div(_templateObject3());
export var PromoWrapper = styled.div(_templateObject4());
export var InputWrapper = styled.div(_templateObject5());
export var ButtonWrapper = styled.div(_templateObject6());
export var ChipsWrapper = styled.div(_templateObject7());