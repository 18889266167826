import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: #28304c;\n  font-size: 18px;\n  font-weight: 700;\n  padding-top: 10px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  text-align: center;\n  vertical-align: center;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  padding: 20px 0\n\n  p {\n    color: #28304c;\n    font-size: 18px;\n    font-weight: 700;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Content = styled.div(_templateObject());
export var Title = styled.div(_templateObject2());