import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  color: #262c3e;\n  font-family: \"Noto Sans\";\n  font-size: 15px;\n  font-weight: 400;\n  line-height: 25px;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 70px;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n    font-size: ", ";\n    min-width: 150px;\n    margin-right: 20px;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  cursor: pointer;\n  min-width: 230px;\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.02em;\n  color: ", ";\n  border-bottom-width: 4px;\n  border-bottom-style: solid;\n  border-bottom-color: ", ";\n  padding-bottom: 25px;\n  margin-right: 60px;\n\n  ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: none;\n  width: 100%;\n  border-bottom: 1px solid ", ";\n  margin-bottom: 70px;\n  overflow: hidden;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  color: #262c3e;\n  font-family: \"Noto Sans\";\n  font-size: 14px;\n  font-weight: 700;\n  line-height: 50px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  color: #262c3e;\n  font-family: \"Noto Sans\";\n  font-size: 16px;\n  font-weight: 400;\n  width: 30%;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  border-bottom: solid 1px #e1e1e9;\n  height: 50px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Item = styled.div(_templateObject2());
export var ItemTitle = styled.div(_templateObject3());
export var ItemDescription = styled.div(_templateObject4());
export var Tabs = styled.div(_templateObject5(), function (props) {
  return props.theme.colors.tabsBorder;
});
export var TabTitle = styled.div(_templateObject6(), function (props) {
  return props.theme.typography.h3FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.active && props.theme.colors.tabTitle;
}, function (props) {
  return props.active ? props.theme.colors.tabTitle : "transparent";
}, media.smallScreen(_templateObject7(), function (props) {
  return props.theme.typography.h4FontSize;
}));
export var AttributeName = styled.span(_templateObject8(), function (props) {
  return props.theme.colors.listAttributeName;
});
export var BlockDetails = styled.div(_templateObject9());
export var Details = styled.div(_templateObject10());