export var footerNavSections = [{
  title: 'contact us',
  items: [{
    name: 'Switchcube Ltd.',
    path: ''
  }, {
    name: '01 2345 67890',
    path: 'tel:01-2345-67890'
  }, {
    name: 'support@switchcube.com',
    path: 'mailto: support@switchcube.com'
  }]
}, {
  title: 'about',
  items: [{
    name: 'About us',
    path: ''
  }, {
    name: 'Careers',
    path: ''
  }, {
    name: 'Investors',
    path: ''
  }]
}, {
  title: 'help',
  items: [{
    name: 'News',
    path: ''
  }, {
    name: 'Delivery & Returns',
    path: ''
  }, {
    name: 'Track Orders',
    path: ''
  }]
}, {
  title: 'other',
  items: [{
    name: 'Privacy & Terms',
    path: ''
  }, {
    name: 'Accessability',
    path: ''
  }, {
    name: 'Contact us',
    path: ''
  }]
}];